export const STATUS_IDENTIFIED = 0
export const STATUS_PROSPECT = 1
export const STATUS_VALIDATED = 2
export const STATUS_LOST = 3
export const STATUS_CLOSED = 4

export function statuses () {
    return [
        { value: STATUS_IDENTIFIED, label: 'Identifié', color: 'blue-600' },
        { value: STATUS_PROSPECT, label: 'Prospect', color: 'blue-600' },
        { value: STATUS_VALIDATED, label: 'Win Actuel', color: 'green-600' },
        { value: STATUS_CLOSED, label: 'Win Passé', color: 'green-600' },
        { value: STATUS_LOST, label: 'Lost', color: 'orange-600' }
    ]
}
